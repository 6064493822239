<template>
  <div>
    <template v-if="isLocationType">
      <p class="s-u-fw-bold mb-3">{{ data.city }} ({{ data.location }})</p>
      <p class="d-flex align-items-center">
        <span class="s-desk-timeline__address-ico">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 12.2 16.3"
            width="12.2"
            height="16.3"
            aria-hidden="true"
            focusable="false"
            role="img"
            class="d-block"
          >
            <path
              fill="#F0AB69"
              d="M5.5 16C.9 9.3 0 8.6 0 6.1 0 2.7 2.7 0 6.1 0s6.1 2.7 6.1 6.1c0 2.5-.9 3.2-5.5 9.9-.2.3-.7.4-1.1.2 0-.1-.1-.2-.1-.2zm.6-7.3c1.4 0 2.5-1.1 2.5-2.5S7.5 3.6 6.1 3.6 3.6 4.7 3.6 6.1s1.1 2.6 2.5 2.6z"
            />
          </svg>
        </span>
        <span class="s-desk-timeline__address-text"> {{ data.address }} </span>
      </p>
    </template>

    <template v-if="isProductType">
      <p class="s-u-fw-bold mb-3">{{ data.name }} ({{ data.category }})</p>
      <p class="d-flex align-items-center">
        <span class="s-desk-timeline__address-ico">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 12 16"
            width="12"
            height="16"
            aria-hidden="true"
            focusable="false"
            role="img"
            class="d-block"
          >
            <path
              fill="#F0AB69"
              d="M11.8 3.3L8.7.2C8.6.1 8.4 0 8.2 0H8v4h4v-.2c0-.2-.1-.4-.2-.5zM7 4.2V0H.7C.3 0 0 .3 0 .7v14.5c0 .4.3.8.7.8h10.5c.4 0 .7-.3.7-.7V5H7.7c-.4 0-.7-.3-.7-.8zm2.2 5.5v.5c0 .3-.2.5-.5.5h-2v2c0 .3-.2.5-.5.5h-.5c-.3 0-.5-.2-.5-.5v-2h-2c-.3 0-.5-.2-.5-.5v-.5c0-.3.2-.5.5-.5h2v-2c0-.3.2-.5.5-.5h.5c.3 0 .5.2.5.5v2h2c.3 0 .5.3.5.5z"
            />
          </svg>
        </span>
        <span class="s-desk-timeline__address-text"> {{ data.description }} </span>
      </p>
    </template>

    <template v-if="isSlotType">
      <p v-if="data.day" class="s-u-fw-bold mb-3">{{ getDay(data.day) }} {{ getDate(data.day) }}</p>
      <p class="d-flex align-items-center">
        <span class="s-desk-timeline__address-ico">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 13.3 16"
            width="13.3"
            height="16"
            aria-hidden="true"
            focusable="false"
            role="img"
          >
            <path
              fill="#F0AB69"
              d="M0 14.5c0 .8.6 1.5 1.4 1.5h10.4c.8 0 1.4-.7 1.4-1.5V6H0v8.5zm1.9-6c0-.3.2-.5.5-.5h8.5c.3 0 .5.2.5.5v2c0 .3-.2.5-.5.5H2.4c-.3 0-.5-.2-.5-.5v-2zm10-6.5h-1.4V.5c-.1-.3-.3-.5-.5-.5H9c-.3 0-.5.2-.5.5V2H4.7V.5c0-.3-.2-.5-.4-.5h-1c-.2 0-.5.2-.5.5V2H1.4C.6 2 0 2.7 0 3.5V5h13.3V3.5c0-.8-.6-1.5-1.4-1.5z"
            />
          </svg>
        </span>
        <span v-if="data.slot" class="s-desk-timeline__address-text"> Odběr v {{ data.slot }} </span>
      </p>
    </template>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import TranslationMixin from '../../mixins/TranslationMixin';
import RESERVATION_STEPS from '../../common/enums/reservationSteps';

import moment from 'moment/min/moment-with-locales';

export default {
  name: 'ReservationSummaryStepDataComponent',

  mixins: [TranslationMixin],

  props: {
    type: {
      type: Number,
      required: true,
    },
  },

  computed: {
    ...mapGetters({
      formData: 'orders/getFormData',
      localization: 'languages/getBrowserLanguage',
      getLocation: 'locations/getLocation',
      getProduct: 'products/getProduct',
      getSlot: 'slots/getSlot',
    }),

    isLocationType() {
      return this.type === RESERVATION_STEPS.LOCATION;
    },

    isProductType() {
      return this.type === RESERVATION_STEPS.PRODUCT;
    },

    isSlotType() {
      return this.type === RESERVATION_STEPS.SLOT;
    },

    isCustomersType() {
      return this.type === RESERVATION_STEPS.CUSTOMERS;
    },

    data() {
      switch (this.type) {
        case RESERVATION_STEPS.LOCATION:
          return this.getLocation(this.formData.locationId) || null;
        case RESERVATION_STEPS.PRODUCT:
          return this.getProduct(this.formData.locationId, this.formData.productId) || null;
        case RESERVATION_STEPS.SLOT:
          return { day: this.formData.reservedDay || null, slot: this.formData.reservedTime || null };
        case RESERVATION_STEPS.CUSTOMERS:
          return this.formData.customers;
        default:
          return null;
      }
    },
  },

  methods: {
    getDay(date) {
      let day = moment(date).format('dddd');
      return day.charAt(0).toUpperCase() + day.slice(1);
    },

    getDate(date) {
      return moment(date).format('D.M.YYYY');
    },
  },

  created() {
    moment.locale('cs');
  },
};
</script>

<style scoped>

</style>
