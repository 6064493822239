import { createStore } from 'vuex';
import locations from './modules/locations';
import languages from './modules/languages';
import products from './modules/products';
import slots from './modules/slots';
import customers from './modules/customers';
import orders from './modules/orders';
import reservations from './modules/reservations';
import me from './modules/me';

export default createStore({
  modules: {
    locations,
    languages,
    products,
    slots,
    customers,
    orders,
    reservations,
    me,
  },
});
