<template>
  <main class="s-l-layout" id="main">
    <ReservationProgressComponent v-if="!isUserAccount"></ReservationProgressComponent>

    <template v-if="showSummary">
      <UserAccountSummary v-if="isUserAccount"></UserAccountSummary>
      <ReservationSummaryComponent v-else></ReservationSummaryComponent>
    </template>

    <router-view></router-view>
  </main>
</template>

<script>
import ReservationSummaryComponent from '../reservation/ReservationSummaryComponent';
import ReservationProgressComponent from '../reservation/ReservationProgressComponent';
import UserAccountSummary from '../user-account/UserAccountSummary';

export default {
  components: {
    ReservationSummaryComponent,
    ReservationProgressComponent,
    UserAccountSummary,
  },

  computed: {
    showSummary() {
      return this.$route.meta.isWithSummary;
    },

    isUserAccount() {
      return this.$route.meta.isUserAccount;
    },
  },
};
</script>

<style scoped>

</style>
