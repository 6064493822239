/* eslint-disable */
// XITE® UTF8 © 2017 – 2021 EMPYREUM®, all rights reserved.

let UTF8 = {
  ByteSize: function (byte) {
    if (byte < 0x80) return 1;
    if ((byte & 0xe0) == 0xc0) return 2;
    if ((byte & 0xf0) == 0xe0) return 3;
    if ((byte & 0xf8) == 0xf0) return 4;
    if ((byte & 0xfc) == 0xf8) return 5;
    if ((byte & 0xfe) == 0xfc) return 6;
    return 0;
  },

  ByteMask: function (size) {
    if (size == 1) return 0x7f;
    else if (size >= 2 && size <= 6) return (1 << (7 - size)) - 1;
    else return 0;
  },

  CodeSize: function (code) {
    switch (true) {
      case code <= 0x0000007f:
        return 1;
      case code <= 0x000007ff:
        return 2;
      case code <= 0x0000ffff:
        return 3;
      case code <= 0x001fffff:
        return 4;
      case code <= 0x03ffffff:
        return 5;
      case code <= 0x7fffffff:
        return 6;
      default:
        break;
    }
    return 0;
  },

  EncodeSize: function (string) {
    var size = 0;
    for (var index = 0, limit = string.length; index < limit; index++) size += this.CodeSize(string.charCodeAt(index));
    return size;
  },

  Encode: function (string) {
    var encodeSize = this.EncodeSize(string);
    var buffer = new ArrayBuffer(encodeSize);
    var array = new Uint8Array(buffer);
    for (var stringIndex = 0, stringLimit = string.length, arrayIndex = 0; stringIndex < stringLimit; stringIndex++) {
      var code = string.charCodeAt(stringIndex);
      if (code >= 0x00000000 && code <= 0x0000007f) {
        array[arrayIndex++] = code & 0x7f;
      } else if (code >= 0x00000080 && code <= 0x0000007ff) {
        array[arrayIndex++] = 0xc0 + ((code >> 6) & 0x1f);
        array[arrayIndex++] = 0x80 + (code & 0x3f);
      } else if (code >= 0x00000800 && code <= 0x00000ffff) {
        array[arrayIndex++] = 0xe0 + ((code >> 12) & 0xf);
        array[arrayIndex++] = 0x80 + ((code >> 6) & 0x3f);
        array[arrayIndex++] = 0x80 + (code & 0x3f);
      } else if (code >= 0x00010000 && code <= 0x0001fffff) {
        array[arrayIndex++] = 0xf0 + ((code >> 18) & 0x7);
        array[arrayIndex++] = 0x80 + ((code >> 12) & 0x3f);
        array[arrayIndex++] = 0x80 + ((code >> 6) & 0x3f);
        array[arrayIndex++] = 0x80 + (code & 0x3f);
      } else if (code >= 0x00200000 && code <= 0x003ffffff) {
        array[arrayIndex++] = 0xf8 + ((code >> 24) & 0x3);
        array[arrayIndex++] = 0x80 + ((code >> 18) & 0x3f);
        array[arrayIndex++] = 0x80 + ((code >> 12) & 0x3f);
        array[arrayIndex++] = 0x80 + ((code >> 6) & 0x3f);
        array[arrayIndex++] = 0x80 + (code & 0x3f);
      } else if (code >= 0x04000000 && code <= 0x07fffffff) {
        array[arrayIndex++] = 0xfc + ((code >> 30) & 0x1);
        array[arrayIndex++] = 0x80 + ((code >> 24) & 0x3f);
        array[arrayIndex++] = 0x80 + ((code >> 18) & 0x3f);
        array[arrayIndex++] = 0x80 + ((code >> 12) & 0x3f);
        array[arrayIndex++] = 0x80 + ((code >> 6) & 0x3f);
        array[arrayIndex++] = 0x80 + (code & 0x3f);
      }
    }
    return buffer;
  },

  Decode: function (buffer, index, limit) {
    (index = index || 0), (limit = limit || buffer.byteLength);
    var array = new Uint8Array(buffer);
    var string = '';
    while (index < limit) {
      var byte = array[index];
      var byteSize = this.ByteSize(byte);
      if (!byteSize || index + byteSize > limit) return undefined; // non-UTF8
      var code = byte & this.ByteMask(byteSize);
      index++;
      while (--byteSize) {
        code <<= 6;
        code |= array[index] & 0x3f;
        index++;
      }
      if (code == 0) return undefined; // [quirk] non-UTF8
      if (code > 0x10ffff) return undefined; // [quirk] non-UTF8
      string += String.fromCharCode(code);
    }
    return string;
  },
};

export { UTF8 };
