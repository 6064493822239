export default {
  namespaced: true,
  state: {
    formData: {
      locationId: null,
      productId: null,
      reservedDay: null,
      reservedTime: null,
      customers: null,
    },
  },

  getters: {
    getFormData: state => state.formData,
  },

  mutations: {
    UPDATE_FORM_DATA: (state, payload) => {
      state.formData = payload;
    },
  },

  actions: {
    updateFormData({ commit, getters }, formData) {
      const updatedFormData = { ...getters.getFormData, ...formData };
      commit('UPDATE_FORM_DATA', updatedFormData);
    },

    createOrder({ getters }) {
      console.log(getters.getFormData);
    },

    setFormForLoggedCustomer({ commit }, me) {
      commit('UPDATE_FORM_DATA', me);
    },
  },
};
