<template>
  <li
    class="s-desk-timeline__item"
    :class="{
      's-desk-timeline__item--current': isActive,
      's-desk-timeline__item--finished': isCompleted || isPreviousCompleted,
    }"
  >
    <span v-if="isCompleted" class="s-u-visualy-hidden"> Dokončený krok: </span>
    <router-link v-if="isPreviousCompleted" :to="{ name: reservationStepRouteName }" class="s-desk-timeline__link">
      <span class="s-desk-timeline__title"> {{ texts.title }} </span>
      <span v-if="isCompleted" class="d-flex align-items-center">
        <span class="d-block">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 8 8"
            width="8"
            height="8"
            aria-hidden="true"
            focusable="false"
            role="img"
          >
            <path fill="currentColor" d="M4 8l.7-.7L2 4.5h6v-1H2L4.8.7 4 0 0 4l4 4z" />
          </svg>
        </span>
        <span class="d-block pl-1"> Zpět na krok {{ type }} </span>
      </span>
    </router-link>
    <template v-if="!isCompleted">
      <span v-if="!isPreviousCompleted" class="s-desk-timeline__link">
        <span class="s-desk-timeline__title"> {{ texts.title }} </span>
      </span>
      <p class="s-desk-timeline__empty-text">{{ texts.empty }}</p>
    </template>
    <div v-if="isCompleted" class="s-desk-timeline__info">
      <h3 class="s-u-visualy-hidden">{{ texts.picked }}</h3>
      <ReservationSummaryStepDataComponent :type="type"></ReservationSummaryStepDataComponent>
    </div>
  </li>
</template>

<script>
import routes from '../../router/routes';
import ReservationSummaryStepDataComponent from './ReservationSummaryStepDataComponent';
import RESERVATION_STEPS from '../../common/enums/reservationSteps';
import { mapGetters } from 'vuex';

export default {
  name: 'ReservationSummaryStepComponent',

  components: {
    ReservationSummaryStepDataComponent,
  },

  props: {
    type: {
      type: Number,
      required: true,
    },
  },

  data() {
    return {
      texts: {},
    };
  },

  computed: {
    ...mapGetters('orders', { formData: 'getFormData' }),

    isActive() {
      return this.$route.meta.reservationStep === this.type;
    },

    isCompleted() {
      switch (this.type) {
        case RESERVATION_STEPS.LOCATION:
          return !!this.formData.locationId;
        case RESERVATION_STEPS.PRODUCT:
          return !!this.formData.productId;
        case RESERVATION_STEPS.SLOT:
          return !!this.formData.reservedTime;
        case RESERVATION_STEPS.CUSTOMERS:
          return !!this.formData.customers;
        default:
          return false;
      }
    },

    isPreviousCompleted() {
      switch (this.type) {
        case RESERVATION_STEPS.LOCATION:
          return true;
        case RESERVATION_STEPS.PRODUCT:
          return !!this.formData.locationId;
        case RESERVATION_STEPS.SLOT:
          return !!this.formData.productId;
        case RESERVATION_STEPS.CUSTOMERS:
          return !!this.formData.reservedTime;
        default:
          return false;
      }
    },

    reservationStepRouteName() {
      const route = routes.find(route => {
        if (!route.meta || !route.meta.reservationStep) {
          return false;
        }

        return route.meta.reservationStep === this.type;
      });

      if (!route) {
        return null;
      }

      return route.name;
    },
  },

  created() {
    switch (this.type) {
      case RESERVATION_STEPS.LOCATION:
        this.texts = {
          title: 'Lokalita',
          picked: 'Vámi vybraná lokalita',
          empty: 'Prosím vyberte lokalitu',
        };
        break;
      case RESERVATION_STEPS.PRODUCT:
        this.texts = {
          title: 'Produkt',
          picked: 'Vámi vybraný produkt',
          empty: 'Prosím vyberte produkt',
        };
        break;
      case RESERVATION_STEPS.SLOT:
        this.texts = {
          title: 'Termín',
          picked: 'Vámi vybraný termín',
          empty: 'Prosím vyberte volný termín',
        };
        break;
      case RESERVATION_STEPS.CUSTOMERS:
        this.texts = {
          title: 'Identifikace',
          picked: 'Vaše informace',
          empty: 'Prosím vyplňte rezervační formulář',
        };
        break;
      default:
        this.texts = {
          title: 'Invalid type',
          picked: 'Invalid type',
          empty: 'Invalid type',
        };
        break;
    }
  },
};
</script>
