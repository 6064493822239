export default {
  namespaced: true,
  state: {
    items: null,
  },
  getters: {
    getAllReservations: state => state.items,
  },
  mutations: {
    SET_RESERVATIONS(state, reservationFiles) {
      state.items = reservationFiles;
    },
  },
  actions: {
    /**
     * sets reservation files
     */
    setReservations({ commit }, data) {
      commit('SET_RESERVATIONS', data);
    },
  },
};
