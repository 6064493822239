/* eslint-disable */
// XITE® XIO © 2007 – 2021 EMPYREUM®, all rights reserved.

console.log('XITE® XIO © 2007 - 2021 EMPYREUM®, all rights reserved.');

import {FILE} from './FILE.js';
import {UTF8} from './UTF8.js';

let constants = {
  TIMEOUT: 1000 * 60 * 1,
  //
  TARGET: '!',
  SOURCE: '?',
  STATUS: '%',
  //
  NODE: 'node',
  UNIT: 'unit',
  CALL: 'call',
  BIND: 'bind',
  JSID: 'jsid',
  //
  FIELD: 'field',
  VALUE: 'value',
};

class XIO {
  Random() {
    return Math.random().toString(36).substr(2);
  }

  Destroy() {
    if (this.test) {
      clearInterval(this.test);
      this.test = undefined;
    }
    //this._link.close();
  }

  Log(node) {
    //return true;
    if (!node) return;
    let text = '';
    let source = true;
    let meta;
    if (
      ((meta = node[this.SOURCE]) && meta[0][this.UNIT]) ||
      (source = false) ||
      ((meta = node[this.TARGET]) && meta[0][this.UNIT])
    ) {
      meta = meta[0];
      let prefix = '[';
      let name;
      if ((name = meta[this.UNIT])) prefix += name[0];
      if ((name = meta[this.CALL])) prefix += ':' + name[0];
      prefix += ']';
      text += prefix + ' ' + (source ? '>' : '<') + ' ';
    }
    let mark = node.Encode();
    let copy = new FILE(mark);
    delete copy[this.TARGET];
    delete copy[this.SOURCE];
    let append = UTF8.Decode(copy.Encode());
    if (!append && append != '') append = '(binary)';
    text += append;
    console.log(text);
  }

  Online(event) {
    if (!this._flag) {
      this._flag = true;
      //console.log("xio online");
    }
    if (this._reconnect !== undefined) {
      clearInterval(this._reconnect);
      this._reconnect = undefined;
    }
    this.Outgoing();
  }

  Offline(event) {
    if (this._flag) {
      this._flag = false;
      //console.log("xio offline");
    }
    this._link = undefined;
    this.Reconnect();
  }

  Failure(event) {
    console.log('xio failure');
  }

  Event(node) {
    let jsid = node.Get(this.TARGET + '/' + this.JSID);
    let request;
    if ((request = this._requests[jsid])) {
      delete this._requests[jsid];
      if (request.timeout) {
        clearTimeout(request.timeout);
        delete request.timeout;
      }
      request.success(node);
    } else {
      let self = this;
      self._handlers.map(function (handler) {
        handler(node);
      });
    }
  }

  Observe(handler) {
    this._handlers.push(handler);
  }

  ObserveCalls(object) {
    this._handlers.push(function (message) {
      let call = message.Get(this.TARGET + '/' + this.CALL);
      let args = {};
      for (let name in message) {
        if (message.hasOwnProperty(name)) {
          if (name == this.TARGET || name == this.SOURCE) continue;
          args[name] = message[name];
        }
      }
      if (object[call]) object[call](args);
      console.log('unimplemented call ' + call); // note: user function can destructure providing named arguments
    });
  }

  Incoming(event) {
    let self = this;
    let reader = new FileReader();
    reader.onload = function () {
      let node = new FILE(reader.result);
      self.Log(node);
      self.Event(node);
    };
    reader.readAsArrayBuffer(event.data);
  }

  Outgoing() {
    if (this._flag) {
      let node;
      while ((node = this._send.shift())) {
        let session;
        if ((session = localStorage.getItem('session'))) node.Set('session', session); // obsolete
        let l;
        if ((l = localStorage.getItem('link'))) node.Set('link', l); // [20200818]
        this.Log(node);
        this._link.send(node.Encode());
      }
    } /* else {
			if(!this._link) {
				this.Connect();
			} else {
				// waiting for connect()
			}
		}*/
  }

  IdSource(node) {
    return node.Get(this.SOURCE + '/' + this.UNIT) + ':' + node.Get(this.SOURCE + '/' + this.CALL);
  }

  IdTarget(node) {
    return node.Get(this.TARGET + '/' + this.UNIT) + ':' + node.Get(this.TARGET + '/' + this.CALL);
  }

  Connect() {
    let link = this._link;
    //if (link) link.close();
    link = this._link = new WebSocket(this._prot + '://' + this._host + ':' + this._port + '/');
    let self = this;
    link.onopen = function (event) {
      self.Online(event);
    };
    link.onclose = function (event) {
      self.Offline(event);
    };
    //link.onerror = function(event) { self.Offline(event); }
    link.onmessage = function (event) {
      self.Incoming(event);
    };
  }

  Reconnect() {
    if (this._reconnect === undefined) {
      this._reconnect = setInterval(this.Connect.bind(this), 1000);
    }
  }

  Message(node) {
    if (!node.Get('+') && !node.Get('-')) {
      // [todo] improve
      if (!node.Get(this.SOURCE + '/' + this.JSID)) {
        node.Set(this.SOURCE + '/' + this.JSID, this.Random());
      }
    }
    this._send.push(node);
    this.Outgoing();
  }

  Request(node, timeout) {
    let self = this;
    return new Promise(function (success, failure) {
      let jsid = self.Random();
      node.Set(self.SOURCE + '/' + self.JSID, jsid);
      let request = (self._requests[jsid] = {
        success: function (response) {
          let status = response.Get(self.STATUS);
          if (!isNaN(status) && status == 0) {
            success(response);
          } else {
            failure(status);
            //failure(self.IdSource(response) + " status " + status);
          }
        },
        //success: success,
        failure: function () {
          failure(undefined);
          //failure(self.IdTarget(node) + " failure");
        },
        timeout: setTimeout(function () {
          let request;
          if ((request = self._requests[jsid])) {
            delete self._requests[jsid];
            //failure(self.IdTarget(node) + " timeout"); // checkme: request.failure();
            failure('timeout');
          }
        }, timeout || self.TIMEOUT),
      });
      self.Message(node);
    });
  }

  MessageUnit(unit, call, data = {}) {
    data[this.TARGET + '/' + this.UNIT] = unit;
    data[this.TARGET + '/' + this.CALL] = call;
    return this.Message(new FILE(data));
  }

  RequestUnit(unit, call, data = {}, timeout) {
    data[this.TARGET + '/' + this.UNIT] = unit;
    data[this.TARGET + '/' + this.CALL] = call;
    return this.Request(new FILE(data), timeout);
  }

  constructor(host, port, prot) {
    for (let c in constants) this[c] = constants[c];
    if (!host) host = window.location.hostname;
    if (!port) port = '';
    if (!prot) prot = window.location.protocol == 'https:' ? 'wss' : 'ws';
    this._host = host;
    this._port = port;
    this._prot = prot;
    this._link = undefined;
    this._send = [];
    this._flag = false;
    this._test = undefined;
    this._requests = {};
    this._handlers = [];
    this._reconnect = undefined;
  }
}

let xio = (window.XIO = new XIO());
xio.Connect();

export { XIO };
