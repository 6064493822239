export class Model {
  attributes = {};

  embeds = {};

  constructor(attributes = {}) {
    this.attributes = { ...attributes };

    if (typeof this.attributes._embedded !== 'undefined') {
      this.embeds = this.attributes._embedded;
      delete this.attributes._embedded;
    }
  }

  get id() {
    return this.attributes.id;
  }

  /**
   * @param {Model} model
   */
  update(model) {
    Object.keys(model.attributes).forEach(attribute => {
      if (!this.attributes[attribute]) {
        this.attributes[attribute] = model.attributes[attribute];
      }
    });
  }

  serialize() {
    return JSON.stringify(this.attributes);
  }

  static unserialize(data) {
    return new this(JSON.parse(data));
  }
}
