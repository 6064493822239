import store from '../../store/store';

const authenticated = (to, from, next) => {
  if (!store.getters['me/getMe']) {
    next({ name: 'ReservationLocation' });
  } else {
    next();
  }
};

export { authenticated };
