import { DayModel } from '../src/common/models/day.model';
import SLOTS_MOCK from './slots';

export default [
  {
    date: '2021-08-20T11:09:33+0200',
    slots: SLOTS_MOCK,
  },
  {
    date: '2021-08-21T11:09:33+0200',
    slots: SLOTS_MOCK,
  },
  {
    date: '2021-08-22T11:09:33+0200',
    slots: SLOTS_MOCK,
  },
  {
    date: '2021-08-23T11:09:33+0200',
    slots: SLOTS_MOCK,
  },
  {
    date: '2021-08-24T11:09:33+0200',
    slots: SLOTS_MOCK,
  },
  {
    date: '2021-08-25T11:09:33+0200',
    slots: SLOTS_MOCK,
  },
  {
    date: '2021-08-26T11:09:33+0200',
    slots: SLOTS_MOCK,
  },
].map(day => new DayModel(day));
