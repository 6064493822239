<template>
  <div>
    <div class="s-access-menu">
      <a href="#main" accesskey="0" class="s-access-menu__link"> Přejít k obsahu </a>
      <a href="#leftside" accesskey="1" class="s-access-menu__link"> Přejít k výběru místa </a>
      <a href="#rightside" accesskey="2" class="s-access-menu__link d-none d-lg-block">
        Přejít k rekapitulaci rezervace
      </a>
      <a href="#mobprogress" accesskey="3" class="s-access-menu__link d-lg-none"> Přejít k postupu rezervace </a>
    </div>

    <AppLayout></AppLayout>
  </div>
</template>

<script>
import AppLayout from './layouts/App.vue';

export default {
  components: {
    AppLayout,
  },
};
</script>

<style lang="scss">
@import './assets/scss/main.scss';
</style>
