<template>
  <div class="s-l-layout__right-side s-u-order-2" id="rightside">
    <h2 class="s-h s-h--secondary">{{ title }}</h2>
    <div class="s-right-box">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SummaryBox',

  props: {
    title: {
      type: String,
      required: true,
    },
  },
};
</script>

<style scoped>

</style>
