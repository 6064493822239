<template>
  <SummaryBoxComponent title="Detaily rezervace">
    <ol class="s-desk-timeline" aria-label="Průběh objednávky a informace o vybraných položkách">
      <ReservationSummaryStepComponent
        v-for="step in Object.values(RESERVATION_STEPS)"
        :key="step"
        :type="step"
      ></ReservationSummaryStepComponent>
    </ol>

    <div class="s-price-box">
      <h3 class="s-u-visualy-hidden">Celková cena objednávky</h3>
      <p class="d-flex justify-content-between mb-4">
        <span> Cena bez DPH </span>
        <span class="s-u-fw-bold"> 0,00 Kč </span>
      </p>
      <p class="d-flex justify-content-between mb-4">
        <span> DPH (0 %) </span>
        <span class="s-u-fw-bold"> 0,00 Kč </span>
      </p>
      <p class="d-flex justify-content-between mb-4">
        <span> Cena s DPH </span>
        <span class="s-u-fw-bold"> 0,00 Kč </span>
      </p>
    </div>
  </SummaryBoxComponent>
</template>

<script>
import SummaryBoxComponent from '../layout/SummaryBoxComponent';
import ReservationSummaryStepComponent from './ReservationSummaryStepComponent';
import RESERVATION_STEPS from '../../common/enums/reservationSteps';

export default {
  components: {
    SummaryBoxComponent,
    ReservationSummaryStepComponent,
  },

  data() {
    return {
      RESERVATION_STEPS,
    };
  },
};
</script>

<style scoped>

</style>
