require('dotenv').config();

import { createApp } from 'vue';
import App from './App.vue';
import router from './router/router';
import store from './store/store';
import multilingualFilter from './common/filters/multilingual';
//import './vendor/usage-example';

const app = createApp(App).use(store).use(router);

app.config.globalProperties.$filters = {
  multilingual: multilingualFilter,
};

app.mount('#app');

console.log(process.env);
