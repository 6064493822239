import * as XITE from '../vendor/XITE/index';

export class XiteApiService {
  static async get(call, data) {
    let xio = (window.XIO = new XITE.XIO('book-dev.anygence.com'));
    xio.Connect();

    return window.XIO.RequestUnit('C19BOOKING', call, data);
  }
}
