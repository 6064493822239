export function get6393Code(ISOLanguageCode) {
  switch (ISOLanguageCode) {
    case 'cs-CZ':
      return 'ces';
    case 'sk-SK':
      return 'slk';
    default:
      return 'eng';
  }
}
