<template>
  <div>
    <h1 class="s-u-visualy-hidden" id="mobprogress">Výběr lokality</h1>
    <div class="s-l-layout__progress">
      <ol class="s-timeline" aria-label="Průběh objednávky">
        <li class="s-timeline__item s-timeline__item--current">
          <span class="s-u-visualy-hidden"> Aktuální krok: </span>
          <span class="s-timeline__link">
            <span class="s-timeline__text"> Vyberte lokalitu </span>
          </span>
        </li>
        <li class="s-timeline__item s-timeline__item--disabled">
          <span class="s-timeline__link">
            <span class="s-timeline__text"> Výběr produktu </span>
          </span>
        </li>
        <li class="s-timeline__item s-timeline__item--disabled">
          <span class="s-timeline__link">
            <span class="s-timeline__text"> Výběr termínu </span>
          </span>
        </li>
        <li class="s-timeline__item s-timeline__item--disabled">
          <span class="s-timeline__link">
            <span class="s-timeline__text"> Identifikace </span>
          </span>
        </li>
      </ol>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ReservationProgressComponent',
};
</script>

<style scoped>

</style>
