export default [
  {
    id: 1,
    time: '9:15',
    availability_type: '#fdd96c',
    filled_percents: 100,
  },
  {
    id: 2,
    time: '9:30',
    availability_type: '#e3f3dc',
    filled_percents: 50,
  },
  {
    id: 3,
    time: '9:45',
    availability_type: '#e3f3dc',
    filled_percents: 30,
  },
  {
    id: 4,
    time: '10:00',
    availability_type: '#fd946c',
    filled_percents: 80,
  },
  {
    id: 5,
    time: '10:15',
    availability_type: '#e3f3dc',
    filled_percents: 40,
  },
  {
    id: 6,
    time: '10:30',
    availability_type: '#fbfb6c',
    filled_percents: 0,
  },
  {
    id: 7,
    time: '10:45',
    availability_type: '#fbfb6c',
    filled_percents: 90,
  },
  {
    id: 8,
    time: '11:00',
    availability_type: '#e3f3dc',
    filled_percents: 100,
  },
  {
    id: 9,
    time: '11:15',
    availability_type: '#fd946c',
    filled_percents: 50,
  },
  {
    id: 10,
    time: '11:30',
    availability_type: '#e3f3dc',
    filled_percents: 10,
  },
];
