export default {
  namespaced: true,
  state: {
    items: [],
  },
  getters: {
    getAllLocations: state => state.items,
    getLocation: state => id => state.items.find(item => item.id === id),
  },
  mutations: {
    SET_LOCATIONS(state, locationFiles) {
      state.items = locationFiles;
    },
    PUSH_LOCATION(state, locationFile) {
      state.items.push(locationFile);
    },
  },
  actions: {
    /**
     * sets locations files
     */
    setLocations({ commit }, data) {
      commit('SET_LOCATIONS', data);
    },

    pushLocation({ commit }, data) {
      commit('PUSH_LOCATION', data);
    },
  },
};
