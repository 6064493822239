<script>
import { mapGetters } from 'vuex';

export default {
  name: 'TranslationMixin',

  computed: {
    ...mapGetters('languages', { defaultLanguageId: 'getDefaultLanguage' }),
  },

  methods: {
    t(text) {
      return this.$filters.multilingual(text, this.defaultLanguageId);
    },
  },
};
</script>
