<template>
  <div>
    <HeaderComponent></HeaderComponent>
    <MainContentComponent></MainContentComponent>
    <FooterComponent></FooterComponent>
  </div>
</template>

<script>
import FooterComponent from '../components/layout/FooterComponent.vue';
import HeaderComponent from '../components/layout/HeaderComponent.vue';
import MainContentComponent from '../components/layout/MainContentComponent.vue';

export default {
  components: {
    HeaderComponent,
    MainContentComponent,
    FooterComponent,
  },

  created() {
    window.addEventListener('beforeunload', e => {
      if (this.$route.meta.isOrderProcess) {
        var confirmationMessage = 'Je možné že provedené změny nebudou uloženy';

        (e || window.event).returnValue = confirmationMessage;
        return confirmationMessage;
      }
    });
  },
};
</script>

<style scoped>

</style>
