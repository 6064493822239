import RESERVATION_STEPS from '../common/enums/reservationSteps';
import { orderProcess } from './guards/orderProcess';
import { authenticated } from './guards/authenticated';

export default [
  {
    path: '/',
    redirect: { name: 'ReservationLocation' },
  },
  {
    path: '/vyber-lokality',
    name: 'ReservationLocation',
    meta: {
      isUserAccount: false,
      isWithSummary: true,
      reservationStep: RESERVATION_STEPS.LOCATION,
    },
    component: () => import(/* webpackChunkName: "ReservationLocation" */ '../views/reservation/ReservationLocation'),
  },
  {
    path: '/vyber-produktu',
    name: 'ReservationProduct',
    meta: {
      isUserAccount: false,
      isWithSummary: true,
      isOrderProcess: true,
      reservationStep: RESERVATION_STEPS.PRODUCT,
    },
    component: () => import(/* webpackChunkName: "ReservationProduct" */ '../views/reservation/ReservationProduct'),
    beforeEnter: orderProcess,
  },
  {
    path: '/vyber-casu',
    name: 'ReservationSlot',
    meta: {
      isUserAccount: false,
      isWithSummary: true,
      isOrderProcess: true,
      reservationStep: RESERVATION_STEPS.SLOT,
    },
    component: () => import(/* webpackChunkName: "ReservationDateTime" */ '../views/reservation/ReservationSlot'),
    beforeEnter: orderProcess,
  },
  {
    path: '/rezervacni-formular',
    name: 'ReservationInfo',
    meta: {
      isUserAccount: false,
      isWithSummary: true,
      isOrderProcess: true,
      reservationStep: RESERVATION_STEPS.CUSTOMERS,
    },
    component: () => import(/* webpackChunkName: "ReservationInfo" */ '../views/reservation/ReservationInfo'),
    beforeEnter: orderProcess,
  },
  {
    path: '/rezervace-dokoncena',
    name: 'ReservationCompleted',
    meta: {
      isUserAccount: false,
      isWithSummary: false,
    },
    component: () => import(/* webpackChunkName: "ReservationCompleted" */ '../views/reservation/ReservationCompleted'),
  },
  {
    path: '/sprava-rezervaci',
    name: 'ReservationsManagement',
    meta: {
      isUserAccount: true,
      isWithSummary: true,
    },
    component: () =>
      import(/* webpackChunkName: "ReservationsManagement" */ '../views/user-account/ReservationsManagement'),
    beforeEnter: authenticated,
  },
  {
    path: '/informace-uzivatele',
    name: 'UserInfo',
    meta: {
      isUserAccount: true,
      isWithSummary: true,
    },
    component: () => import(/* webpackChunkName: "UserInfo" */ '../views/user-account/UserInfo'),
    beforeEnter: authenticated,
  },
  {
    path: '/editace-uzivatele',
    name: 'UserAccountEditInfo',
    meta: {
      isUserAccount: true,
      isWithSummary: false,
    },
    component: () => import(/* webpackChunkName: "UserInfo" */ '../components/user-account/UserAccountEditInfo'),
    beforeEnter: authenticated,
  },
  {
    path: '/reset-password',
    name: 'ResetPassword',
    meta: {
      isUserAccount: true,
      isWithSummary: false,
    },
    component: () => import(/* webpackChunkName: "UserInfo" */ '../views/user-account/ResetPassword'),
    beforeEnter: authenticated,
  },
];
