import { Model } from '../parents/model';
import { SlotModel } from './slot.model';

export class DayModel extends Model {
  static ATTR_ID = 'id';
  static ATTR_DATE = 'date';
  static ATTR_SLOTS = 'slots';

  get date() {
    return this.attributes[DayModel.ATTR_DATE];
  }

  get slots() {
    return this.attributes[DayModel.ATTR_SLOTS].map(slot => new SlotModel(slot));
  }

  getDayString(localization) {
    const date = this.attributes[DayModel.ATTR_DATE];
    return new Date(date).toLocaleDateString(localization, { weekday: 'long' });
  }

  getDateString(localization) {
    const date = this.attributes[DayModel.ATTR_DATE];
    return new Date(date).toLocaleDateString(localization, { year: 'numeric', month: 'numeric', day: 'numeric' });
  }

  getSlotByTime(time) {
    return this.slots.find(slot => slot.time === time);
  }
}
