import { get6393Code } from '../../common/helpers/languages';

export default {
  namespaced: true,
  state: {
    defaultLanguage: window.navigator.languages ? window.navigator.languages[0] : window.navigator.language,
  },
  getters: {
    getBrowserLanguage: state => state.defaultLanguage,
    getDefaultLanguage: state => get6393Code(state.defaultLanguage),
  },
};
