import { Model } from '../parents/model';

export class CustomerModel extends Model {
  static ATTR_CUSTOMER_ID = 'id';
  static ATTR_NAME = 'name';
  static ATTR_SURNAME = 'surname';
  static ATTR_BIRTHDATE = 'birthdate';
  static ATTR_NATIONALITY = 'nationality';
  static ATTR_NATIONALID = 'nationalid';
  static ATTR_INSURANCE = 'insurance';
  static ATTR_PHONE = 'phone';
  static ATTR_EMAIL = 'email';
  static ATTR_STREET = 'street';
  static ATTR_CITY = 'city';
  static ATTR_ZIP = 'zip';
  static ATTR_COUNTRY = 'country';

  get id() {
    return isValid(this.attributes[CustomerModel.ATTR_CUSTOMER_ID]);
  }

  get name() {
    return isValid(this.attributes[CustomerModel.ATTR_NAME]);
  }

  get surname() {
    return isValid(this.attributes[CustomerModel.ATTR_SURNAME]);
  }

  get fullName() {
    if (isValid(this.attributes[CustomerModel.ATTR_NAME]) && isValid(this.attributes[CustomerModel.ATTR_SURNAME])) {
      return `${this.attributes[CustomerModel.ATTR_NAME][0]} ${this.attributes[CustomerModel.ATTR_SURNAME][0]}`;
    } else {
      return null;
    }
  }

  get birthdate() {
    return isValid(this.attributes[CustomerModel.ATTR_BIRTHDATE]);
  }

  get age() {
    const birthDate = new Date(this.birthdate);
    const ageDiff = Date.now() - birthDate;
    const ageDate = new Date(ageDiff); // miliseconds from epoch

    return Math.abs(ageDate.getUTCFullYear() - 1970);
  }

  get nationality() {
    return isValid(this.attributes[CustomerModel.ATTR_NATIONALITY]);
  }

  get nationalid() {
    return isValid(this.attributes[CustomerModel.ATTR_NATIONALID]);
  }

  get insurance() {
    return isValid(this.attributes[CustomerModel.ATTR_INSURANCE]);
  }

  get phone() {
    return isValid(this.attributes[CustomerModel.ATTR_PHONE]);
  }

  get email() {
    return isValid(this.attributes[CustomerModel.ATTR_EMAIL]);
  }

  get street() {
    return isValid(this.attributes[CustomerModel.ATTR_STREET]);
  }

  get city() {
    return isValid(this.attributes[CustomerModel.ATTR_CITY]);
  }

  get zip() {
    return isValid(this.attributes[CustomerModel.ATTR_ZIP]);
  }

  get country() {
    return isValid(this.attributes[CustomerModel.ATTR_COUNTRY]);
  }

  getDateString(localization) {
    const date = this.attributes[CustomerModel.ATTR_BIRTHDATE];
    return new Date(date).toLocaleDateString(localization, { year: 'numeric', month: 'numeric', day: 'numeric' });
  }
}

function isValid(attribute) {
  return attribute ? attribute[0] : null;
}
