<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    style="background: transparent; position: absolute; top: 5rem; left: 50%; transform: translateX(-50%); z-index: 2"
    width="200px"
    height="200px"
    viewBox="0 0 100 100"
    preserveAspectRatio="xMidYMid"
  >
    <g transform="translate(11.11111111111111 50)">
      <circle cx="0" cy="0" r="2" fill="#5bbba1">
        <animateTransform
          attributeName="transform"
          type="scale"
          begin="-0.5271084337349398s"
          calcMode="spline"
          keySplines="0.3 0 0.7 1;0.3 0 0.7 1"
          values="0;1;0"
          keyTimes="0;0.5;1"
          dur="1.2048192771084336s"
          repeatCount="indefinite"
        ></animateTransform>
      </circle>
    </g>
    <g transform="translate(22.22222222222222 50)">
      <circle cx="0" cy="0" r="2" fill="#5bbba1">
        <animateTransform
          attributeName="transform"
          type="scale"
          begin="-0.4518072289156626s"
          calcMode="spline"
          keySplines="0.3 0 0.7 1;0.3 0 0.7 1"
          values="0;1;0"
          keyTimes="0;0.5;1"
          dur="1.2048192771084336s"
          repeatCount="indefinite"
        ></animateTransform>
      </circle>
    </g>
    <g transform="translate(33.333333333333336 50)">
      <circle cx="0" cy="0" r="2" fill="#5bbba1">
        <animateTransform
          attributeName="transform"
          type="scale"
          begin="-0.3765060240963855s"
          calcMode="spline"
          keySplines="0.3 0 0.7 1;0.3 0 0.7 1"
          values="0;1;0"
          keyTimes="0;0.5;1"
          dur="1.2048192771084336s"
          repeatCount="indefinite"
        ></animateTransform>
      </circle>
    </g>
    <g transform="translate(44.44444444444444 50)">
      <circle cx="0" cy="0" r="2" fill="#5bbba1">
        <animateTransform
          attributeName="transform"
          type="scale"
          begin="-0.3012048192771084s"
          calcMode="spline"
          keySplines="0.3 0 0.7 1;0.3 0 0.7 1"
          values="0;1;0"
          keyTimes="0;0.5;1"
          dur="1.2048192771084336s"
          repeatCount="indefinite"
        ></animateTransform>
      </circle>
    </g>
    <g transform="translate(55.55555555555556 50)">
      <circle cx="0" cy="0" r="2" fill="#5bbba1">
        <animateTransform
          attributeName="transform"
          type="scale"
          begin="-0.2259036144578313s"
          calcMode="spline"
          keySplines="0.3 0 0.7 1;0.3 0 0.7 1"
          values="0;1;0"
          keyTimes="0;0.5;1"
          dur="1.2048192771084336s"
          repeatCount="indefinite"
        ></animateTransform>
      </circle>
    </g>
    <g transform="translate(66.66666666666667 50)">
      <circle cx="0" cy="0" r="2" fill="#5bbba1">
        <animateTransform
          attributeName="transform"
          type="scale"
          begin="-0.1506024096385542s"
          calcMode="spline"
          keySplines="0.3 0 0.7 1;0.3 0 0.7 1"
          values="0;1;0"
          keyTimes="0;0.5;1"
          dur="1.2048192771084336s"
          repeatCount="indefinite"
        ></animateTransform>
      </circle>
    </g>
    <g transform="translate(77.77777777777777 50)">
      <circle cx="0" cy="0" r="2" fill="#5bbba1">
        <animateTransform
          attributeName="transform"
          type="scale"
          begin="-0.0753012048192771s"
          calcMode="spline"
          keySplines="0.3 0 0.7 1;0.3 0 0.7 1"
          values="0;1;0"
          keyTimes="0;0.5;1"
          dur="1.2048192771084336s"
          repeatCount="indefinite"
        ></animateTransform>
      </circle>
    </g>
    <g transform="translate(88.88888888888889 50)">
      <circle cx="0" cy="0" r="2" fill="#5bbba1">
        <animateTransform
          attributeName="transform"
          type="scale"
          begin="0s"
          calcMode="spline"
          keySplines="0.3 0 0.7 1;0.3 0 0.7 1"
          values="0;1;0"
          keyTimes="0;0.5;1"
          dur="1.2048192771084336s"
          repeatCount="indefinite"
        ></animateTransform>
      </circle>
    </g>
  </svg>
</template>

<script>
export default {
  name: 'Loader',
};
</script>

<style scoped>

</style>
