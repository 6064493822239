<template>
  <footer class="s-footer">
    <div class="s-footer__links">
      <a href="javascript:void(0)" class="s-footer__link">Všeobecné obchodní podmínky</a>
      <a href="javascript:void(0)" class="s-footer__link">Technická podpora</a>
    </div>
    <div class="s-footer__copyright">@ 2021 ANYGENCE, s.r.o.</div>
  </footer>
</template>

<script>
export default {};
</script>

<style scoped>

</style>
