import store from '../../store/store';

const orderProcess = (to, from, next) => {
  if (!store.getters['orders/getFormData'].locationId) {
    next({ name: 'ReservationLocation' });
  } else {
    next();
  }
};

export { orderProcess };
