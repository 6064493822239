<template>
  <SummaryBoxComponent title="Profil uživatele">
    <div v-if="loggedCustomer" class="s-profile">
      <h3 class="s-profile__name">{{ loggedCustomer.fullName }}</h3>
      <div class="s-profile__personal-info">
        <p class="s-profile__row">
          <span class="s-profile__col s-u-fw-bold"> Datum narození: </span>
          <span class="s-profile__col"> {{ loggedCustomer.getDateString(localization) }} </span>
        </p>
        <p class="s-profile__row">
          <span class="s-profile__col s-u-fw-bold"> Rodné číslo: </span>
          <span class="s-profile__col"> {{ loggedCustomer.nationalid }} </span>
        </p>
        <p class="s-profile__row">
          <span class="s-profile__col s-u-fw-bold"> Národnost: </span>
          <span class="s-profile__col"> {{ loggedCustomer.country }} </span>
        </p>
        <p class="s-profile__row">
          <span class="s-profile__col s-u-fw-bold"> Pojišťovna: </span>
          <span class="s-profile__col"> {{ loggedCustomer.insurance }} </span>
        </p>
      </div>
      <div class="s-profile__info">
        <p class="s-profile__info-box">
          <span class="s-profile__info-title"> Trvalé bydliště </span>
          <span class="s-profile__info-text">
            <span class="d-block">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 12.2 16.3"
                width="12.2"
                height="16.3"
                aria-hidden="true"
                focusable="false"
                role="img"
                class="d-block"
              >
                <path
                  fill="#F0AB69"
                  d="M5.5 16C.9 9.3 0 8.6 0 6.1 0 2.7 2.7 0 6.1 0s6.1 2.7 6.1 6.1c0 2.5-.9 3.2-5.5 9.9-.2.3-.7.4-1.1.2 0-.1-.1-.2-.1-.2zm.6-7.3c1.4 0 2.5-1.1 2.5-2.5S7.5 3.6 6.1 3.6 3.6 4.7 3.6 6.1s1.1 2.6 2.5 2.6z"
                />
              </svg>
            </span>
            <span class="s-profile__text">
              {{ loggedCustomer.street }}, {{ loggedCustomer.zip }} {{ loggedCustomer.city }}
            </span>
          </span>
        </p>
        <p class="s-profile__info-box">
          <span class="s-profile__info-title"> Kontaktní údaje </span>
          <span class="s-profile__info-text">
            <span class="d-block">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 16 16"
                width="16"
                height="16"
                aria-hidden="true"
                focusable="false"
                role="img"
                class="d-block"
              >
                <path
                  fill="#F0AB69"
                  d="M14.3 0H1.7C.8 0 0 .8 0 1.7v12.6c0 .9.8 1.7 1.7 1.7h12.6c.9 0 1.7-.8 1.7-1.7V1.7c0-.9-.8-1.7-1.7-1.7zm-.6 11l-.5 2.3c-.1.2-.3.4-.5.4-5.8 0-10.4-4.6-10.4-10.3 0-.2.2-.5.4-.5L5 2.3h.1c.2 0 .4.1.5.3l1.1 2.5v.2c0 .2-.1.3-.2.4L5.2 6.8c.8 1.7 2.2 3.1 4 4l1.1-1.4c.1-.1.3-.2.4-.2h.2l2.5 1.1c.2.1.3.3.3.5v.2z"
                />
              </svg>
            </span>
            <span class="s-profile__text"> {{ loggedCustomer.phone }} / {{ loggedCustomer.email }} </span>
          </span>
        </p>
        <!--p class="s-profile__info-box">
          <span class="s-profile__info-title"> Přidružené osoby ({{ me.customers.length }}) </span>
          <span v-for="(customer, index) in me.customers" :key="index" class="s-profile__info-text mb-3">
            <span class="d-block">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 16 16"
                width="16"
                height="16"
                aria-hidden="true"
                focusable="false"
                role="img"
                class="d-block"
              >
                <path
                  fill="#F0AB69"
                  d="M0 16v-2c0-2.2 3.6-4 8-4s8 1.8 8 4v2H0zM4 4c0-2.2 1.8-4 4-4s4 1.8 4 4-1.8 4-4 4-4-1.8-4-4z"
                />
              </svg>
            </span>
            <span class="s-profile__text">
              {{ customer.name }} {{ customer.surname }} ({{ customer.age }} let)
            </span>
          </span>
        </p-->
      </div>
      <a @click="editInfo" class="s-btn s-btn--primary-h-w w-100"> Aktualizovat údaje </a>
    </div>
  </SummaryBoxComponent>
</template>

<script>
import { mapGetters } from 'vuex';
import SummaryBoxComponent from '../layout/SummaryBoxComponent';

export default {
  name: 'UserAccountSummary',

  components: {
    SummaryBoxComponent,
  },

  computed: {
    ...mapGetters({
      me: 'me/getMe',
      getCustomer: 'customers/getCustomer',
      customers: 'customers/getAllCustomers',
      localization: 'languages/getBrowserLanguage',
    }),

    loggedCustomer() {
      if (!this.me) {
        return null;
      }

      return this.me;
    },
  },

  methods: {
    editInfo() {
      this.$router.push({ name: 'UserAccountEditInfo' });
    },
  },
};
</script>

<style scoped>

</style>
