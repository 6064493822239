<template>
  <div class="s-modal" :class="{ 's-modal--hide': !showModal }">
    <transition name="pop">
      <div v-if="show" class="s-modal__inside" role="dialog" aria-labelledby="dialog-1-title" aria-modal="true">
        <button class="s-modal__close" @click="handleCloseModal">
          <span class="s-u-visualy-hidden"> Zavřít dialog </span>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 10 10"
            width="10"
            height="10"
            aria-hidden="true"
            focusable="false"
            role="img"
            class="d-block"
          >
            <path
              fill="currentColor"
              d="M6.9 5l2.8-2.8c.3-.3.3-.9 0-1.3L9.1.3C8.8 0 8.2 0 7.8.3L5 3.1 2.2.3C1.9 0 1.3 0 .9.3L.3.9c-.3.3-.3.9 0 1.3L3.1 5 .3 7.8c-.3.3-.3.9 0 1.3l.6.6c.3.3.9.3 1.3 0L5 6.9l2.8 2.8c.3.3.9.3 1.3 0l.6-.6c.3-.3.3-.9 0-1.3L6.9 5z"
            />
          </svg>
        </button>
        <slot />
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  name: 'Modal',

  data() {
    return {
      show: false,
    };
  },

  props: {
    showModal: {
      type: Boolean,
      default: false,
    },
  },

  methods: {
    handleCloseModal() {
      this.$emit('close');
    },

    handleOpenModal() {
      this.$emit('open');
    },
  },

  mounted() {
    this.show = true;
  },
};
</script>

<style scoped>

</style>
