import { Model } from '../parents/model';

export class SlotModel extends Model {
  static ATTR_ID = 'id';
  static ATTR_TIME = 'time';
  static ATTR_AVAILABILITY_TYPE = 'availability_type';
  static ATTR_FILLED_PERCENTS = 'filled_percents';

  get time() {
    return this.attributes[SlotModel.ATTR_TIME];
  }

  get availabilityType() {
    return this.attributes[SlotModel.ATTR_AVAILABILITY_TYPE];
  }

  get filledPercents() {
    return this.attributes[SlotModel.ATTR_FILLED_PERCENTS];
  }

  get isFull() {
    return this.filledPercents === 100;
  }

  get isNearlyFull() {
    return this.filledPercents >= 70;
  }
}
