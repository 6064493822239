import SLOTS_MOCK from '../../../mocks/slots';
import DAYS_MOCK from '../../../mocks/days';

export default {
  namespaced: true,
  state: {
    days: DAYS_MOCK,
    items: SLOTS_MOCK,
  },
  getters: {
    getAllDays: state => state.days,
    getAllSlots: state => state.items,
    getSlot: state => id => state.items.find(item => item.id === id),
  },
  mutations: {},
  actions: {},
};
