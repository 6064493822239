/* eslint-disable */
// XITE® FILE © 2017 – 2021 EMPYREUM®, all rights reserved.

import {UTF8} from './UTF8.js';

function Type(object) {
  let type = '';
  if (object) {
    if (object.constructor) {
      type = object.constructor.name;
    } else {
      console.log('[todo] Type();');
      console.log(object);
    }
  } else type = typeof object;
  type = type.toLowerCase();
  return type;
}

function Bufferize(data) {
  if (data === undefined || data === null) return new ArrayBuffer();
  switch (Type(data)) {
    case 'arraybuffer':
      break;
    case 'string':
      data = UTF8.Encode(data);
      break;
    case 'number':
      data = UTF8.Encode(data.toString());
      break;
    case 'boolean':
      data = UTF8.Encode(data ? 'true' : 'false');
      break;
    default:
      console.log('unable to bufferize');
      console.log(data);
      console.trace();
      data = undefined;
      break;
  }
  return data;
}

function Typize(data) {
  switch (Type(data)) {
    case 'arraybuffer':
      var string;
      if ((string = UTF8.Decode(data)) !== undefined) {
        // number support removed to support inputs starting with '0'
        /*if(string.length && !isNaN(string))
					data = Number(string); // "Number"
				else*/
        data = string; // "String"
      } // else: "ArrayBuffer"
      break;
    default:
      console.log('unable to typize ' + Constructor(data));
      data = undefined;
      break;
  }
  return data;
}

function DataEncodeSize(data) {
  var size = 0;
  var inputBuffer = Bufferize(data);
  var input = new Uint8Array(inputBuffer),
    inputSize = input.length;
  for (var inputOffset = 0; inputOffset < inputSize; inputOffset++) {
    switch (input[inputOffset]) {
      case 0x0a:
      case 0x0d:
      case 0x3c:
      case 0x3e:
      case 0x7f:
        size++;
      default:
        size++;
    }
  }
  return size;
}

function DataEncode(output, outputOffset, data) {
  var outputOffsetStart = outputOffset;
  var inputBuffer = Bufferize(data);
  var input = new Uint8Array(inputBuffer),
    inputSize = input.length;
  for (var inputOffset = 0; inputOffset < inputSize; inputOffset++) {
    var byte = input[inputOffset];
    switch (byte) {
      case 0x0a:
      case 0x0d:
      case 0x3c:
      case 0x3e:
      case 0x7f:
        output[outputOffset++] = 0x7f;
        output[outputOffset++] = byte | 0x80;
        break;
      default:
        output[outputOffset++] = byte;
        break;
    }
  }
  return outputOffset - outputOffsetStart;
}

function DataDecodeSize(input, inputOffset, inputLimit) {
  var size = 0;
  while (inputOffset < inputLimit) if (input[inputOffset++] != 0x7f) size++;
  return size;
}

function DataDecode(input, inputOffset, inputLimit) {
  var outputBuffer = new ArrayBuffer(DataDecodeSize(input, inputOffset, inputLimit));
  var output = new Uint8Array(outputBuffer),
    outputOffset = 0;
  var esc = false;
  while (inputOffset < inputLimit) {
    var byte = input[inputOffset++];
    if (!esc) {
      if (byte == 0x7f) esc = true;
      else output[outputOffset++] = byte;
    } else {
      output[outputOffset++] = byte & 0x7f;
      esc = false;
    }
  }
  return outputBuffer;
}

function EncodeCalculate(node) {
  var size = 0;
  for (var name in node) {
    if (!node.hasOwnProperty(name)) continue;
    var values = node[name];
    //for(var index in values) {
    for (var index = 0, limit = values.length; index < limit; index++) {
      size += 1 + DataEncodeSize(name) + 1;
      var value = values[index];
      if (value instanceof FILE) size += EncodeCalculate(value);
      else size += DataEncodeSize(value);
      size += 3;
    }
  }
  return size;
}

function EncodeConstructName(output, outputOffset, node, name) {
  var outputOffsetStart = outputOffset;
  var values = node[name];
  for (var index = 0, limit = values.length; index < limit; index++) {
    output[outputOffset++] = 0x3c;
    outputOffset += DataEncode(output, outputOffset, name);
    output[outputOffset++] = 0x3e;
    var value = values[index];
    if (value instanceof FILE) outputOffset += EncodeConstruct(output, outputOffset, value);
    else outputOffset += DataEncode(output, outputOffset, value);
    (output[outputOffset++] = 0x3c), (output[outputOffset++] = 0x2f), (output[outputOffset++] = 0x3e);
  }
  return outputOffset - outputOffsetStart;
}

function EncodeConstruct(output, outputOffset, node) {
  var outputOffsetStart = outputOffset;
  //if(node.hasOwnProperty("@")) outputOffset += nodeEncodeConstructName(output, outputOffset, node, "@"); // NOMY-specific
  for (var name in node) {
    if (!node.hasOwnProperty(name)) continue;
    //if(name == "@") continue; // NOMY-specific
    outputOffset += EncodeConstructName(output, outputOffset, node, name);
  }
  return outputOffset - outputOffsetStart;
}

class FILE {
  constructor(data) {
    switch (Type(data)) {
      case 'arraybuffer':
      case 'string':
        this.Decode(data);
        break;
      case 'object':
        for (var name in data) {
          if (!data.hasOwnProperty(name)) continue;
          var value = data[name];
          if (value !== undefined) {
            if (Type(value) == 'object') value = new FILE(value); // [checkme]
            this.Set(name, value);
          }
        }
        break;
      case 'undefined':
        break;
      default:
        console.log('unable to decode');
        log(data);
        console.trace();
        break;
    }
  }

  Encode() {
    //log("--8<--"); log(this);
    var outputBuffer = new ArrayBuffer(EncodeCalculate(this));
    var output = new Uint8Array(outputBuffer);
    EncodeConstruct(output, 0, this);
    return outputBuffer;
  }

  Decode(data) {
    var inputBuffer = Bufferize(data);
    var input = new Uint8Array(inputBuffer),
      inputSize = input.length,
      inputOffset = 0;
    var stack = new Array();
    stack.push(this);
    var name = undefined,
      value = undefined;
    var esc = false,
      end = false,
      get = false,
      tag;
    var unset = -1,
      lt = unset,
      gt = unset;
    for (var inputOffset = 0; inputOffset < inputSize; inputOffset++) {
      var byte = input[inputOffset];
      if (byte & 0x80) esc = false;
      if (byte != 0x7f) {
        switch (byte) {
          case 0x3c: // '<'
            tag = byte;
            get = !end;
            end = false;
            lt = inputOffset;
            break;
          case 0x3e: // '>'
            if (tag == 0x3c) {
              if (!end) {
                if (lt != unset) {
                  value = undefined;
                  if (name !== undefined) {
                    var parent = stack[stack.length - 1];
                    parent[name].push(new FILE());
                    stack.push(parent[name][parent[name].length - 1]);
                    name = undefined;
                  }
                  if (!stack.length) {
                    console.log('parse error');
                    return;
                  }
                  var parent = stack[stack.length - 1];
                  name = Typize(DataDecode(input, lt + 1, inputOffset));
                  let type = Type(name);
                  if (type != 'string' && type != 'number') {
                    console.log('non-textual names not supported');
                    return;
                  }
                  if (parent[name] === undefined) parent[name] = [];
                  if (parent[name]._ === undefined) parent[name]._ = [];
                  //									Object.defineProperty(parent[name], "original", { value:  });
                }
              } else {
                if (gt != unset) {
                  if (get) {
                    var token = DataDecode(input, gt + 1, lt);
                    var value = Typize(token);
                    var parent = stack[stack.length - 1];
                    parent[name].push(value);
                    parent[name]._.push(token);
                    //if(token != value)
                    //	Object.defineProperty(value, "original", { value: token });
                  }
                }
              }
            }
            tag = byte;
            gt = inputOffset;
            if (end) {
              if (!get) {
                if (stack.length < 1) {
                  console.log('stack underflow');
                  return undefined;
                }
                stack.pop();
              }
              (name = undefined), (value = undefined);
            }
            break;
          case 0x2f: // '/'
            if (tag == 0x3c) end = true;
            break;
        }
      } else esc = true;
    }
  }

  Degree(path) {
    var node = this;
    var components = path.split('/');
    for (var c = 0; c < components.length; c++) {
      var component = components[c];
      if (!component.length) continue;
      var parts = component.split(':');
      if (parts.length) {
        var name = parts[0];
        var index = parts.length > 1 ? parseInt(parts[1]) : 0;
        if (node[name] === undefined) break;
        if (node[name][index] === undefined) break;
        if (c + 1 == components.length) return node[name].length - index;
        node = node[name][index];
      }
    }
    return 0;
  }

  Get(path) {
    var node = this;
    var components = path.split('/');
    for (var c = 0; c < components.length; c++) {
      var component = components[c];
      if (!component.length) continue;
      var parts = component.split(':');
      if (parts.length) {
        var name = parts[0];
        var index = parts.length > 1 ? parseInt(parts[1]) : 0;
        if (node[name] === undefined) return undefined;
        if (node[name][index] === undefined) return undefined;
        node = node[name][index];
      }
    }
    return node;
  }

  Set(path, data) {
    var node = this;
    var components = path.split('/');
    let path2 = '';
    for (var c = 0; c < components.length; c++) {
      var component = components[c];
      if (!component.length) continue;
      var parts = component.split(':');
      if (parts.length) {
        var name = parts[0];
        //var index = parts.length > 1 ? parseInt(parts[1]) : 0;
        var index = 0;
        if (parts.length > 1) {
          if (path2.length) path2 += '/';
          path2 += name;
          if (parts[1] == '*') index = this.Degree(path2);
          else index = parseInt(parts[1]);
          if (index) path2 += ':' + index;
        }
        if (node[name] === undefined) node[name] = [];
        if (
          node[name][index] === undefined ||
          !(node[name][index] instanceof FILE) // overwrite
        ) {
          if (c + 1 == components.length) {
            if (data !== undefined && data !== null) {
              // [201912] fix
              if (data instanceof FILE) node[name][index] = data;
              else {
                // node[name][index] = Typize(Bufferize(data));
                if (Type(data) != 'arraybuffer') data = Typize(Bufferize(data));
                node[name][index] = data;
              }
            } else {
              // unset (aka rid)
              node[name].splice(index, 1);
            }
            return;
          }
          node[name][index] = new FILE();
        }
        node = node[name][index];
      }
    }
  }

  Rid(path) {
    return this.Set(path);
  }

  Name(path) {
    let components = path.split('/');
    let field = components.pop();
    let parts = field.split(':');
    let name = parts[0];
    return name;
  }

  ForEachUUIDField(callback) {
    let file = this;
    for (let field in file) {
      if (file.hasOwnProperty(field)) {
        let value = file[field];
        if (value && root.UUID.UUID.isValid(value[0])) callback(field);
      }
    }
  }

  ForEachField(field, callback) {
    let node = this;
    let values = this[field];
    if (values) {
      for (let index = 0; index < values.length; index++) {
        callback(values[index], index);
      }
    }
  }

  PairLookup(field, value) {
    let file = this;
    for (let f in file) {
      if (file.hasOwnProperty(f)) {
        let v = file[f];
        if (v) {
          let i;
          if ((i = v.indexOf(value)) >= 0) return i;
        }
      }
    }
    return undefined;
  }

  PairIndex(field, value) {
    // new
    let file = this;
    for (let f in file) {
      if (file.hasOwnProperty(f)) {
        let v = file[f];
        if (v) {
          let i;
          if ((i = v.indexOf(value)) >= 0) return i;
        }
      }
    }
    return -1;
  }

  PairExists(field, value) {
    // obsolete
    return this.PairIndex(field, value) >= 0;
  }

  toString() {
    return Typize(this.Encode());
  }
}

export { FILE };
