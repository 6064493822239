import { XiteApiService } from '../../services/xite.api.service';
import { CustomerModel } from '../../common/models/customer.model';

export default {
  namespaced: true,
  state: {
    me: null,
  },
  getters: {
    getMe: state => state.me,
  },
  mutations: {
    SET_ME(state, locationFiles) {
      state.me = locationFiles;
    },
  },
  actions: {
    setMe({ commit }, data) {
      commit('SET_ME', data);
    },

    getMeData({ dispatch }, customerId) {
      return new Promise(resolve => {
        XiteApiService.get('GetItems', {
          file: customerId,
          items: 'name surname birthdate nationalid nationality insurance phone email street city zip country',
        }).then(customer => {
          customer.id = customerId;
          dispatch('setMe', new CustomerModel(customer));
          resolve();
        });
      });
    },
  },
};
