import { XiteApiService } from '../../services/xite.api.service';
import { CustomerModel } from '../../common/models/customer.model';

export default {
  namespaced: true,
  state: {
    customer: null,
    sessionToken: window.localStorage.getItem('sessionToken') ? window.localStorage.getItem('sessionToken') : null,
  },
  getters: {
    getCustomer: state => state.customer,
  },
  mutations: {},
  actions: {
    getCustomerData(customerId) {
      return new Promise(resolve => {
        XiteApiService.get('GetItems', {
          file: customerId,
          items: 'name surname birthdate nationalid nationality insurance phone email street city zip country',
        }).then(customer => {
          customer.id = customerId;
          this.setMe(new CustomerModel(customer));
          resolve();
          this.showLogin = false;
          this.sessionResolved = true;
        });
      });
    },
  },
};
