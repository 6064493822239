export default {
  namespaced: true,
  state: {
    items: [],
  },
  getters: {
    getAllProducts: state => state.items,
    getAllLocationProducts: state => locationId => state.items.find(item => item.locationId === locationId)?.products,
    getProduct: state => (locationId, id) =>
      state.items.find(item => item.locationId === locationId)?.products.find(item => item.id === id),
  },
  mutations: {
    SET_PRODUCTS(state, data) {
      state.items.push({ locationId: data.locationId, products: data.products });
    },
  },
  actions: {
    /**
     * sets product files
     */
    setProducts({ commit }, data) {
      commit('SET_PRODUCTS', data);
    },
  },
};
